import * as React from "react";
import { graphql } from "gatsby";
import ComponentTemplatePage from "../../../../components/pages/templates/ComponentTemplatePage";

const ComponentTemplate = ({ data }) => (
  <ComponentTemplatePage
    title="Content Components | UI Components Library | Uizard"
    category="Component Templates"
    url="/templates/component-templates/content/"
    metaDescription="Uizard's content UI component templates will help you design professional and creative layouts for any app or website. Explore in the Uizard app."
    description="
    h2:Perfect for content-heavy designs
    <br/>
    Uizard's content UI components will help you design professional and creative layouts for any app or website.
    From <a:https://uizard.io/templates/mobile-app-templates/>designing mobile apps</a> that will hit the top of the App Store to your personal blog, we have you covered.
    <br/>
    h3:Easily create layouts with different content types
    <br/>
    Whether you're brainstorming new ideas for apps or websites or simply want to visualize what you already had in mind, our <a:https://uizard.io/templates/component-templates/>UI components library</a> will help you go from A to B quickly and easily.
    These templates allow you to create layouts and designs featuring different content types, such as landing page content, product listings, and articles.
    <br/>
    h3:Design compelling content layouts for your app or website
    <br/>
    The template you choose can change the way users interact with your app, the overall user experience of your app, and how much time is spent in the app.
    Choose the template that fits your idea best among Uizard's growing list of content component template and use Uizard's theming engine to style everything to your likings in minutes.
    "
    pages={[
      "Article entries",
      "Blog post entry",
      "Blog post overview",
      "Blog post row",
      "Blog post tiles overview",
      "Blog posts column",
      "Call to action with visual",
      "Card feed 1 image",
      "Card feed 2 images",
      "Card feed 3 images",
      "Card row",
      "Cart overview",
      "Comments or reviews",
      "Content card with feature",
      "Content card with picture",
      "Details and thumbnails",
      "Feature cards round image",
      "Header hero signup",
      "Image post in feed",
      "Item details",
      "Item list with visuals",
      "Landing page content",
      "Landing page section",
      "Large landing page section",
      "Mobile image feed",
      "Product categories",
      "Product category with visuals",
      "Product listing details",
      "Profile page",
      "Shop product overview",
      "Simple content card",
      "Social media postcard",
      "Tiny content highlight card",
      "Web page section",
    ]}
    img1={data.image1.childImageSharp}
    img1alt="Screenshot showcasing the component template for designing blogs"
    img2={data.image2.childImageSharp}
    img2alt="Screenshot showcasing the component template for designing blogs"
    img3={data.image3.childImageSharp}
    img3alt="Screenshot showcasing the component template for designing blogs"
    img4={data.image4.childImageSharp}
    img4alt="Screenshot showcasing the component template for designing blogs"
    img5={data.image5.childImageSharp}
    img5alt="Screenshot showcasing the component template for designing blogs"
  />
);

export default ComponentTemplate;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    image1: file(
      relativePath: {
        eq: "templates/component-templates/content/content_1.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image2: file(
      relativePath: {
        eq: "templates/component-templates/content/content_2.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image3: file(
      relativePath: {
        eq: "templates/component-templates/content/content_3.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image4: file(
      relativePath: {
        eq: "templates/component-templates/content/content_4.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image5: file(
      relativePath: {
        eq: "templates/component-templates/content/content_5.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
